import { FunctionComponent, useRef, useState } from "react";
import TextInput from "../../../../../components/Forms/TextInput";
import { Message, useForm } from "react-hook-form";
import Datepicker from "../../../../../components/Forms/Datepicker";
import CheckboxInput from "../../../../../components/Forms/CheckboxInput";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../../../components/Forms/Button";
import { RegistrationFormData } from "../../index";
import { useTranslation } from "react-i18next";
import { EMAIL_PATTERN, RECAPTCHA_KEY } from "../../../../../utils/constants";
import * as Sentry from "@sentry/react";

interface Props {
  onSubmit: (data: RegistrationFormData, reset: () => void) => Promise<void>;
  disabled: boolean;
}

const RegistrationForm: FunctionComponent<Props> = ({ onSubmit, disabled }) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<RegistrationFormData>({ reValidateMode: "onChange" });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  if (errors) {
    Sentry.captureMessage(`Registration errors: ${JSON.stringify(errors)}`);
  }
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);
  const { t } = useTranslation();

  const submit = handleSubmit(async (data) => {
    onSubmit(
      { ...data, "g-recaptcha-response": recaptchaToken },
      () => recaptchaRef.current && recaptchaRef.current.reset(),
    );
  });

  return (
    <form onSubmit={submit}>
      <h4 className="uppercase font-bold text-blue-500 py-4 text-sm">
        {t("forms.sections.registration.Account")}
      </h4>
      <div className="grid grid-cols-2">
        <TextInput
          label={t("fields.FirstName")}
          name="first_name"
          register={register}
          options={{ required: true, maxLength: 255 }}
          error={errors.first_name}
        />

        <TextInput
          label={t("fields.LastName")}
          name="last_name"
          register={register}
          options={{ required: true, maxLength: 255 }}
          error={errors.last_name}
        />

        <TextInput
          label={t("fields.Email")}
          name="email"
          register={register}
          options={{ required: true, pattern: EMAIL_PATTERN }}
          error={errors.email}
        />

        <TextInput
          label={t("fields.Phone")}
          name="phone"
          placeholder="+421911 000 000"
          register={register}
          options={{ required: true }}
          error={errors.phone}
        />

        <TextInput
          label={t("fields.PasswordRegistration")}
          type="password"
          name="password"
          register={register}
          options={{ required: true, minLength: 8 }}
          error={errors.password}
        />

        <TextInput
          label={t("fields.ConfirmPassword")}
          type="password"
          name="password_confirmation"
          register={register}
          error={errors.password_confirmation}
          options={{
            required: true,
            validate: {
              confirm: (value: string) => {
                return (
                  value === getValues("password") ||
                  (t("forms.validationMessages.confirm") as Message)
                );
              },
            },
          }}
        />
      </div>

      <div className="border-b border-blue-300 w-4/6 my-8" />
      <h4 className="uppercase font-bold text-blue-500 py-4 text-sm">
        {t("forms.sections.registration.Personal")}
      </h4>

      <TextInput
        label="Ulica a čislo"
        name="street"
        register={register}
        options={{ required: true }}
        error={errors.street}
      />

      <div className="grid grid-cols-2">
        <TextInput
          label={t("fields.Zip")}
          name="zip"
          register={register}
          options={{ required: true }}
          error={errors.zip}
        />

        <TextInput
          label={t("fields.City")}
          name="city"
          register={register}
          options={{ required: true }}
          error={errors.city}
        />

        <TextInput
          label={t("fields.ProfileImage")}
          name="profile_image"
          type="file"
          register={register}
          error={errors.profile_image}
          options={{ required: false }}
        />

        <Datepicker
          control={control}
          name="birthDate"
          label={t("fields.BirthDate")}
          required={true}
        />
      </div>
      <div className="border-b border-blue-300 w-4/6 my-8" />
      <h4 className="uppercase font-bold text-blue-500 py-4 text-sm">
        {t("forms.sections.registration.Nbs")}
      </h4>
      <TextInput
        label={t("fields.Registration")}
        name="nbs_registration_number"
        register={register}
        type="number"
        error={errors.nbs_registration_number}
        options={{ required: true }}
      />

      <div className="border-b border-blue-300 w-4/6 my-8" />
      <div className="grid gid-cols md:grid-cols-2 pb-8">
        <div>
          <CheckboxInput
            label={
              <span>
                Oboznámil som sa s{" "}
                <a
                  href="/pages/ochrana-osobnych-udajov"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-800"
                >
                  Pravidlá o ochrane osobných údajov
                </a>{" "}
                a súhlasím so spracúvaním mojich osobných údajov
              </span>
            }
            messageLabel="Pole"
            name="terms_and_conditions"
            register={register}
            placement="top"
            options={{ required: true }}
          />
        </div>
        <div className="pt-6">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            onChange={setRecaptchaToken}
          />
        </div>
      </div>
      <div className="text-center">
        <Button
          type="submit"
          size="xl"
          disabled={recaptchaToken === null || disabled}
        >
          {t("forms.actions.Register")}
        </Button>
      </div>
    </form>
  );
};

export default RegistrationForm;
