const BASE_API_URL = "https://api.bafpro.sk";
//const BASE_API_URL = "http://localhost:8000"
const RECAPTCHA_KEY = "6LchjzgUAAAAAFDYtBNHdiTYXaBNxAyjKBYy38O4";
/* eslint-disable */
const EMAIL_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const URL_PATTERN =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

/* eslint-enable */


const DISPLAY_PATTERNS = {
  [URL_PATTERN.toString()]: "http://vasadomena.sk",
  [EMAIL_PATTERN.toString()]: "email@email.com",  
};

const CONTACT_EMAIL = "info@bafpro.sk";
const G_ANANLYTICS_KEY = "G-RMBP36Q831";

export {
  BASE_API_URL,
  RECAPTCHA_KEY,
  EMAIL_PATTERN,
  URL_PATTERN,
  DISPLAY_PATTERNS,
  CONTACT_EMAIL,
  G_ANANLYTICS_KEY,
};
