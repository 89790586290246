import {setAuthToken} from "../../../store/app-slice";
import {useAppDispatch} from "../../../store";
import {toast} from "react-toastify";
import {useNavigate, useLocation} from "react-router-dom";
import Heading from "../../../components/Heading";
import HeaderImage from "../../../components/HeaderImage";
import {LoginData, useLoginMutation, useVerifyUserMutation} from "../../../api/user/api";
import {useTranslation} from "react-i18next";
import LoginForm from "./components/LoginForm";
import {useState} from "react";
import ResendDialog from "./components/ResendDialog";
import {LoginResponse} from "../../../types";
import * as Sentry from "@sentry/react";
import axios from 'axios'
import { BASE_API_URL } from "../../../utils/constants";

const Login = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const dispatch = useAppDispatch();
  const [postLogin, {isLoading}] = useLoginMutation();
  const [verifyUserEmail, {isLoading: isLoadingVerify}] = useVerifyUserMutation();
  const [resendToken, setResendToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const query = new URLSearchParams(useLocation().search);

  const hash = query.get('hash')
  const token = query.get('token')
  const next  = query.get('next')

  const setToken = (response: LoginResponse, message: string) => {
    toast.success(message)
    dispatch(setAuthToken(response));
    navigate(next ? next : "/");
  }

  const verifyEmail = async (hash: string, token: string, response: LoginResponse) => {
    try {
      await verifyUserEmail({
        hash,
        token,
        authToken: response.access_token
      }).unwrap()

      setToken(response, t("messages.VerificationEmailSuccess"))
    } catch (e: any) {
      Sentry.captureException(e);
      toast.error(t("messages.VerificationEmailError"))
      setResendToken(response.access_token)
    }
  }

  const submitLoging = (data: LoginData, reset: () => void) => {
    if (data['g-recaptcha-response'] !== null && !isLoading && !isLoadingVerify) {
      (async () => {
        try {
          await axios.get(`${BASE_API_URL}/sanctum/csrf-cookie`)
          const response = await postLogin(data).unwrap()
          if (response.verified) {
            setToken(response, t('forms.sections.login.success'))
            return
          }
          // Verification process began with /login?token=token&hash=hash
          // when verification pass user will be login automatically
          // when verification fail we show button to resend the verification email
          if (hash && token && !isLoadingVerify) {
            await verifyEmail(hash, token, response)
          } else {
            // For show the resend the mail in case user miss it
            setResendToken(response.access_token)
          }
        } catch (e: any) {
          Sentry.captureException(e.data ? e.data : e);
          toast.error(t('forms.sections.login.error'))
          reset()
        }
      })()
    }
  }

  return (
    <div>
      <HeaderImage imageUrl={imageUrl}/>
      <div className="mx-auto p-4 py-12 max-w-2xl ">
        <Heading>{t('forms.sections.login.Title')}</Heading>
        {next ? (
          <div className="pb-12">
            <h4 className="font-bold text-red-600">Pre pokračovanie sa prihláste, ak nemáte konto musíte sa najprv zaregistrovať.</h4>
          </div>
        ) : null}
        <ResendDialog token={resendToken}/>
        <LoginForm onSubmit={submitLoging} disabled={isLoading}/>
      </div>
    </div>
  )
}

export default Login
