import { useForm } from "react-hook-form";
import TextInput from "../../../../../components/Forms/TextInput";
import Button from "../../../../../components/Forms/Button";
import { useAuthUser } from "../../../../../components/UserState/hooks";
import { useTranslation } from "react-i18next";
import Section from "../../../Profile/components/Section";
import { URL_PATTERN } from "../../../../../utils/constants";
import React, { FunctionComponent } from "react";
import { EditProfileFormData } from "../../../../../types";
const FormSelect = React.lazy(
  () => import("../../../../../admin/components/Forms/FormSelect"),
);

interface Props {
  onSubmit: (data: EditProfileFormData) => Promise<void>;
  disabled: boolean;
}

const EditProfileForm: FunctionComponent<Props> = ({ onSubmit, disabled }) => {
  const user = useAuthUser();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditProfileFormData>({ reValidateMode: "onChange" });

  const submit = handleSubmit((data) => onSubmit(data));

  const enabledRegions = user?.regions
    ? user.regions.filter((region) => region.enabled).map((r) => r.id)
    : [];
  const enabledContacts = user?.contracts
    ? user.contracts.filter((contract) => contract.enabled).map((c) => c.id)
    : [];
  const enabledSectors = user?.sectors
    ? user.sectors.filter((sector) => sector.enabled).map((s) => s.id)
    : [];
  return (
    <form onSubmit={submit}>
      <Section title={t("profile.Personal")}>
        <div className="grid md:grid-cols-2 grid-cols">
          <div className="py-8">
            <img
              src={user?.profile_image || "/profile-default-image.png"}
              alt="profile"
              className="mx-auto max-h-64 w-auto rounded-full border border-gray-700"
            />
          </div>
          <div className="flex flex-wrap content-center">
            <TextInput
              label={t("fields.ProfileImage")}
              name="profileImage"
              type="file"
              register={register}
              error={errors.profileImage}
              options={{ required: false }}
            />
          </div>
          <TextInput
            label={t("fields.FirstName")}
            defaultValue={user?.first_name}
            name="first_name"
            register={register}
            options={{ required: true, maxLength: 255 }}
            error={errors.first_name}
          />

          <TextInput
            label={t("fields.LastName")}
            defaultValue={user?.last_name}
            name="last_name"
            register={register}
            options={{ required: true, maxLength: 255 }}
            error={errors.last_name}
          />

          <TextInput
            label={t("fields.Phone")}
            defaultValue={user?.phone}
            name="phone"
            register={register}
            options={{ required: true }}
            error={errors.phone}
          />

          <div className="col-span-1 md:col-span-2">
            <TextInput
              label="Ulica a čislo"
              defaultValue={user?.street}
              name="street"
              register={register}
              options={{ required: true, maxLength: 255 }}
              error={errors.street}
            />
          </div>
          <TextInput
            label={t("fields.Zip")}
            defaultValue={user?.zip}
            name="zip"
            register={register}
            options={{ required: true, maxLength: 20 }}
            error={errors.zip}
          />

          <TextInput
            label={t("fields.City")}
            defaultValue={user?.city}
            name="city"
            register={register}
            options={{ required: true, maxLength: 255 }}
            error={errors.city}
          />
        </div>
      </Section>
      <Section title={t("profile.Company")} show={!!user?.registration?.number}>
        <div className="grid md:grid-cols-2 grid-cols">
          <div className="py-8">
            {user?.registration?.logo ? (
              <img
                src={user?.registration?.logo}
                alt="profile"
                className="mx-auto max-h-64 w-auto rounded-full border border-gray-700"
              />
            ) : null}
          </div>
          <div className="flex flex-wrap content-center">
            <TextInput
              label={t("fields.Logo")}
              name="logo"
              type="file"
              register={register}
              error={errors.logo}
              options={{ required: false }}
            />
          </div>

          <TextInput
            label={t("fields.Website")}
            name="website"
            register={register}
            defaultValue={user?.registration?.website || ""}
            options={{ required: false, maxLength: 255, pattern: URL_PATTERN }}
            error={errors.website}
          />
          <div className="col-span-2">
            <TextInput
              label={t("fields.Description")}
              name="description"
              register={register}
              defaultValue={user?.registration?.description || ""}
              options={{ required: false, maxLength: 255 }}
              error={errors.description}
            />
          </div>
        </div>
      </Section>
      <Section
        title={t("profile.Sectors")}
        show={(user?.sectors && user?.sectors.length > 0) || false}
      >
        <FormSelect
          options={
            user?.sectors?.map((sector) => ({
              value: sector.id,
              label: sector.name,
            })) || []
          }
          value={enabledSectors}
          variant="standard"
          size="small"
          label={t("profile.Sectors")}
          name="sectors"
          control={control}
          multiple={true}
        />
      </Section>
      <Section
        title={t("profile.Contracts")}
        show={(user?.contracts && user?.contracts.length > 0) || false}
      >
        <FormSelect
          options={
            user?.contracts?.map((contract) => ({
              value: contract.id,
              label: contract.name,
            })) || []
          }
          value={enabledContacts}
          label={t("profile.Contracts")}
          name="contracts"
          control={control}
          variant="standard"
          size="small"
          multiple={true}
        />
      </Section>
      <Section
        title={t("profile.Regions")}
        show={(user?.regions && user?.regions?.length > 0) || false}
        hideDivider={true}
      >
        <FormSelect
          options={
            user?.regions?.map((region) => ({
              value: region.id,
              label: region.name,
            })) || []
          }
          value={enabledRegions}
          label={t("profile.Regions")}
          name="regions"
          control={control}
          variant="standard"
          size="small"
          multiple={true}
        />
      </Section>
      <div className="text-center pt-16">
        <Button size="xl" disabled={disabled}>
          {t("forms.actions.Save")}
        </Button>
      </div>
    </form>
  );
};

export default EditProfileForm;
