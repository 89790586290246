import { useSubscribeEventMutation, useEventDetailQuery } from "../../../api/events/api";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import TextInput from "../../../components/Forms/TextInput";
import React, { useEffect, useRef, useState } from "react";
import Section from "../../User/Profile/components/Section";
import CheckboxInput from "../../../components/Forms/CheckboxInput";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_KEY } from "../../../utils/constants";
import Button from "../../../components/Forms/Button";
import PageLayout from "../../../layout/PageLayout";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../utils/errors";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import {useAuthUser} from "../../../components/UserState/hooks";

interface FormData {
  email: string,
  newsletter: boolean,
  terms_and_conditions: boolean,
}


const EventDetail = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAuthUser();
  const { t } = useTranslation();
  const params = useParams();
  let eventId: number = 0;
  try {
    eventId = parseInt(params.eventId || '0')
  } catch (e: any) {
    const error = e.data ? e.data : e
    Sentry.captureException(error);
    toast.error(resolveErrorMessage(error, t("error.ParseParam")))
    navigate('/events')
  }
  // @ts-ignore
  const { data: event, error, isLoading: isLoadingEvents } = useEventDetailQuery(!!eventId ? eventId : 0);

  useEffect(() => {
    // @ts-ignore
    if (error?.status === 403) {
        navigate(`/login?next=${location.pathname}`)
    } else if ((!event || event?.id !== eventId) && !isLoadingEvents) {
      navigate('/events');
    }
  }, [event, error, eventId, navigate, isLoadingEvents, location.pathname]);

  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm<FormData>({ reValidateMode: 'onChange' });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<null | string>(null);
  const [subscribe, { isLoading: isLoadingSubscribe }] = useSubscribeEventMutation();

  const submitForm = handleSubmit(async (formData) => {
    if (!isLoadingSubscribe) {
      try {
        await subscribe({ id: eventId, email: formData.email }).unwrap();
        toast.success(t("messages.UserCourseSubscribe"))
        navigate('/events')
      } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error, t("messages.UserCourseSubscribe")))
      }
    }
  })

  return isLoadingEvents ? (<Loader />) : (
    <PageLayout imageUrl={imageUrl}>
      <form onSubmit={submitForm}>
        <Section title={t('forms.sections.registration.Title')}>
          <h2 className="font-bold pb-4 ">{event?.name}</h2>
          <div >
            <TextInput
              label={t('fields.Email')}
              name="email"
              defaultValue={user?.email}
              register={register}
              error={errors.email}
              options={{ required: false }}
            />
          </div>
          <div className="grid gid-cols md:grid-cols-2 pb-8">
            <div>
              <CheckboxInput
                label={<span>Oboznámil som sa s <a href='/pages/ochrana-osobnych-udajov' target="_blank" className="text-blue-500 hover:text-blue-800">Pravidlá o ochrane osobných údajov</a> a súhlasím so spracúvaním mojich osobných údajov</span>}
                messageLabel="pravidla poživania"
                name="terms_and_conditions"
                register={register}
                error={errors.terms_and_conditions}
                placement="top"
                options={{ required: true }} />
            </div>
            <div className="pt-6">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                onChange={setRecaptchaToken}
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              type="submit"
              size="xl"
              disabled={recaptchaToken === null || isLoadingSubscribe}>
              {t('forms.actions.Register')}
            </Button>
          </div>
        </Section>
      </form>
    </PageLayout>
  )
}

export default EventDetail
