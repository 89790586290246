import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {axiosBaseQuery} from "../index";
import {AdminListParams, Event, EventType, Paginated, SuccessResponse} from "../../types";
import {EventFormData} from "../../admin/components/EventForm";
import {format} from "date-fns";


export const EventApi = createApi({
  reducerPath: 'events',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Events'],
  endpoints: (build) => ({
    userEvents: build.query<Event[], void>({
      query: () => ({
        url: `/events`,
        method: 'get'
      }),
      providesTags: ['Events']
    }),
    eventDetail: build.query<Event, void>({
      query: (id: number | void ) => ({
        url: `/events/${id}`,
        method: 'get'
      }),
      providesTags: ['Events']
    }),
    eventTypes:  build.query<EventType[], null>({
      query: () => ({
        url: `/event-types`,
        method: 'get'
      }),
    }),
    adminEvents: build.query<Paginated<Event>, AdminListParams>({
      query: ({search, page = 1, size = 10}) => ({
        url: `/admin/events?page=${page}&size=${size}&search=${search}`,
        method: 'get'
      }),
      providesTags: ['Events']
    }),
    adminEventDetail: build.query<Event, { id: number }>({
      query: ({id}) => ({url: `/admin/events/${id}`, method: 'get'}),
      providesTags: ['Events']
    }),
    createEvent: build.mutation<SuccessResponse, EventFormData>({
      query: (data) => {
        const startDate = format(data.startDate, 'dd.LL.yyyy HH:mm').toString()
        let registerUntil = null
        if (data.registerUntil) {
          registerUntil = format(data.registerUntil, 'dd.LL.yyyy').toString()
        }
        return {url: `/admin/events`, method: 'post', data: {...data, startDate, registerUntil}}
      },
      invalidatesTags: ['Events']
    }),
    editEvent: build.mutation<SuccessResponse, { data: EventFormData, id: number }>({
      query: ({data, id}) => {
        const startDate = format(data.startDate, 'dd.LL.yyyy HH:mm').toString()
        let registerUntil = null
        if (data.registerUntil) {
          registerUntil = format(data.registerUntil, 'dd.LL.yyyy').toString()
        }
        return {url: `/admin/events/${id}`, method: 'put', data: {...data, startDate, registerUntil}}
      },
      invalidatesTags: ['Events']
    }),
    deleteEvent: build.mutation<SuccessResponse, number>({
      query: (id) => ({url: `/admin/events/${id}`, method: 'delete'}),
      invalidatesTags: ['Events']
    }),
    subscribeEvent: build.mutation<SuccessResponse, {id: number, email: string}>({
      query: ({id, email}) => ({url: `/events/${id}/subscribe`, method: 'post', data: {email}}),
      invalidatesTags: ['Events']
    }),
    unsubscribeEventAdmin: build.mutation<SuccessResponse, {id: number, email: string}>({
      query: ({id, email}) => ({url: `/admin/events/${id}/${email}/unsubscribe`, method: 'post'}),
      invalidatesTags: ['Events']
    }),
    subscribeEventAdmin: build.mutation<SuccessResponse, {id: number, email: string, sendEmail: boolean}>({
      query: ({id, email}) => ({url: `/admin/events/${id}/${email}/subscribe`, method: 'post'}),
      invalidatesTags: ['Events']
    })
  }),
});

export const {
  useUserEventsQuery,
  useEventDetailQuery,
  useEventTypesQuery,
  useSubscribeEventMutation,
  useAdminEventsQuery,
  useAdminEventDetailQuery,
  useEditEventMutation,
  useCreateEventMutation,
  useDeleteEventMutation,
  useUnsubscribeEventAdminMutation,
  useSubscribeEventAdminMutation,
} = EventApi;
